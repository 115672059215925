<template>
  <div>
    <div class="d-flex justify-content-end align-items-center">
      <div>
        <FormGroup
          v-if="$permissions.has(['all_scheduled_reports', 'company_admin'])"
          id="allUsers"
          v-model="options.allUsers"
          class="mb-0"
          type="radio"
          label="Show"
          :options="[
            { label: 'All Company', value: true },
            { label: 'Own Only', value: false }
          ]"
          @input="() => refresh()"
        />
      </div>
      <button class="btn btn-primary" @click="onClickNew">New Scheduled Report</button>
    </div>
    <DataTable
      :key="reloadTable"
      :items="scheduledReports"
      :total="scheduledReportsTotal"
      :loading="scheduledReportsLoading.list"
      :columns="columns"
      :page-size="20"
      :fetch-items="refresh"
      default-sort-key="createdAt"
      :default-sort-direction="-1"
      :other-params="otherParams"
      no-hover
    >
      <template v-slot:name="{ data }">
        <strong>{{ data.name }}</strong>
      </template>
      <template v-slot:createdAt="{ data }">
        {{ data.createdAt | date('DD MMM YYYY HH:mm:ss') }}
      </template>
      <template v-slot:schedule="{ data }">
        {{ getNextRunTime(data) | date('ddd Do MMM YYYY HH:mm') }}
      </template>
      <template v-slot:lastRunDate="{ data }">
        <span v-if="data.lastRunDate" class="mr-1">{{ data.lastRunDate | date('ddd Do MMM YYYY HH:mm') }}</span>
        <router-link
          v-if="data.lastRunReportId"
          class="btn btn-sm btn-text-primary"
          :to="{
            name: 'reports-download',
            params: { id: data.lastRunReportId }
          }"
          ><i class="fa fa-file-invoice mr-1"></i>Download</router-link
        >
      </template>
      <template v-slot:enabled="{ data }">
        <i v-if="data.enabled" class="fa fa-lg fa-circle-check text-success"></i>
        <i v-else class="fa fa-lg fa-circle-xmark text-danger"></i>
      </template>
      <template v-slot:userSub="{ data }">
        {{ data.userSub | user(users) }}
      </template>
      <template v-slot:actions="{ data }">
        <div class="d-flex">
          <button class="btn btn-sm btn-text-primary mr-2" @click="onClickEdit(data)"><i class="fa fa-pencil mr-2"></i>Edit</button>
          <button class="btn btn-sm btn-text-dark mr-2" @click="onClickSendTest(data)"><i class="fa fa-paper-plane mr-2"></i>Send Test</button>
          <button class="btn btn-sm btn-text-danger" @click="modals.delete = data"><i class="fa fa-trash mr-2"></i>Delete</button>
        </div>
      </template>
    </DataTable>
    <Modal
      :open="!!modals.edit"
      title="Schedule Report"
      text="Send out this report to users on a regular or one-off basis. You can edit scheduled reports at any time by going to the Reports > Schedule tab once created."
      lg-size
      :loading="modals.edit?.saving"
      @close="modals.edit = false"
      @submit="onSaveReport"
    >
      <ScheduledReportForm v-if="modals.edit" v-model="modals.edit.form" />
    </Modal>
    <Modal
      :open="!!modals.delete"
      title="Delete Schedule"
      text="Are you sure you want to unschedule this report? You can re-schedule it again at any time."
      @close="modals.delete = false"
      @submit="onDeleteSchedule"
    >
    </Modal>
    <Modal
      :open="!!modals.sendTest"
      title="Send Test"
      confirm-text="Send"
      :loading="scheduledReportsLoading.send"
      @close="modals.sendTest = false"
      @submit="onSendTest"
    >
      Send an example of this scheduled report to your own email address <strong>{{ $auth.user.email }}</strong
      >.
    </Modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import DataTable from '@/components/DataTable';
import Modal from '@/components/ConfirmModal';
import ScheduledReportForm from '@/components/forms/ScheduledReportForm';
import FormGroup from '@/components/FormGroup';

import { getNextRunTimes } from '@/lib/schedule';

const initialScheduleForm = {
  name: '',
  startDate: new Date(),
  reportPeriod: '1|month',
  frequency: '1|month',
  frequencyDay: 'any',
  frequencyPeriod: 'any',
  subject: '',
  message: '',
  recipients: [],
  enabled: true,
  reportTemplateId: null,
  overrides: {}
};

export default {
  name: 'ReportSchedule',
  components: {
    DataTable,
    FormGroup,
    Modal,
    ScheduledReportForm
  },
  data() {
    return {
      otherParams: {},
      modals: {
        edit: false,
        delete: false,
        sendTest: false
      },
      reloadTable: 0,
      options: {
        allUsers: false
      },
      lastParams: {
        data: {
          params: {}
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      scheduledReports: 'scheduledReport/scheduledReports',
      scheduledReportsTotal: 'scheduledReport/total',
      scheduledReportsLoading: 'scheduledReport/loadingAction',
      users: 'company/users'
    }),
    columns() {
      return [
        { name: 'Name', code: 'name', search: true },
        { name: 'Next Scheduled For', code: 'schedule' },
        { name: 'Last Run', code: 'lastRunDate' },
        { name: 'Enabled', code: 'enabled' },
        ...(this.$permissions.has(['all_scheduled_reports', 'company_admin']) ? [{ name: 'Created By', code: 'userSub' }] : []),
        { name: 'Actions', code: 'actions', noSort: true }
      ];
    }
  },
  mounted() {
    this.listUsers({
      id: this.$auth.companyId
    });
  },
  methods: {
    ...mapActions({
      listScheduledReports: 'scheduledReport/list',
      updateScheduledReport: 'scheduledReport/update',
      removeScheduledReport: 'scheduledReport/remove',
      sendScheduledReport: 'scheduledReport/send',
      createScheduledReport: 'scheduledReport/create',
      listUsers: 'company/listUsers'
    }),
    getNextRunTime(schedule) {
      const nextRunTimes = getNextRunTimes(schedule);

      return nextRunTimes.length > 0 ? nextRunTimes[0] : 'N/A';
    },
    onClickEdit(data) {
      this.modals.edit = {
        id: data._id,
        form: { ...initialScheduleForm, ...data, _id: undefined }
      };
    },
    onClickNew() {
      this.modals.edit = {
        id: null,
        form: { ...initialScheduleForm }
      };
    },
    onClickSendTest(data) {
      this.modals.sendTest = data;
    },
    async onSaveReport() {
      this.modals.edit.saving = true;

      if (this.modals.edit.form.overrides) {
        this.modals.edit.form.overrides = Object.entries(this.modals.edit.form.overrides).reduce((acc, [key, value]) => {
          if (value) {
            acc[key] = value;
          }

          return acc;
        }, {});
      }

      if (this.modals.edit.id) {
        await this.updateScheduledReport({
          id: this.modals.edit.id,
          data: this.modals.edit.form
        });
      } else {
        await this.createScheduledReport({
          data: this.modals.edit.form
        });
      }

      this.reloadTable += 1;

      this.modals.edit = false;
    },
    async onDeleteSchedule() {
      await this.removeScheduledReport({
        id: this.modals.delete._id
      });

      this.reloadTable += 1;

      this.modals.delete = false;
    },
    async onSendTest() {
      await this.sendScheduledReport({ id: this.modals.sendTest._id, data: { recipients: [this.$auth.user.email], nextRunDate: new Date() } });

      this.modals.sendTest = false;
    },
    refresh(params) {
      if (params) this.lastParams = params;

      const requestParams = params || this.lastParams;

      this.listScheduledReports({
        data: {
          ...requestParams.data,
          params: {
            ...requestParams.data.params,
            ...(this.options.allUsers ? {} : { userSub: this.$auth.user.sub })
          }
        }
      });
    }
  }
};
</script>
