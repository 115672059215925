var render = function () {
  var _vm$modals$edit;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-content-end align-items-center"
  }, [_c('div', [_vm.$permissions.has(['all_scheduled_reports', 'company_admin']) ? _c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "allUsers",
      "type": "radio",
      "label": "Show",
      "options": [{
        label: 'All Company',
        value: true
      }, {
        label: 'Own Only',
        value: false
      }]
    },
    on: {
      "input": function input() {
        return _vm.refresh();
      }
    },
    model: {
      value: _vm.options.allUsers,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "allUsers", $$v);
      },
      expression: "options.allUsers"
    }
  }) : _vm._e()], 1), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.onClickNew
    }
  }, [_vm._v("New Scheduled Report")])]), _c('DataTable', {
    key: _vm.reloadTable,
    attrs: {
      "items": _vm.scheduledReports,
      "total": _vm.scheduledReportsTotal,
      "loading": _vm.scheduledReportsLoading.list,
      "columns": _vm.columns,
      "page-size": 20,
      "fetch-items": _vm.refresh,
      "default-sort-key": "createdAt",
      "default-sort-direction": -1,
      "other-params": _vm.otherParams,
      "no-hover": ""
    },
    scopedSlots: _vm._u([{
      key: "name",
      fn: function fn(_ref) {
        var data = _ref.data;
        return [_c('strong', [_vm._v(_vm._s(data.name))])];
      }
    }, {
      key: "createdAt",
      fn: function fn(_ref2) {
        var data = _ref2.data;
        return [_vm._v(" " + _vm._s(_vm._f("date")(data.createdAt, 'DD MMM YYYY HH:mm:ss')) + " ")];
      }
    }, {
      key: "schedule",
      fn: function fn(_ref3) {
        var data = _ref3.data;
        return [_vm._v(" " + _vm._s(_vm._f("date")(_vm.getNextRunTime(data), 'ddd Do MMM YYYY HH:mm')) + " ")];
      }
    }, {
      key: "lastRunDate",
      fn: function fn(_ref4) {
        var data = _ref4.data;
        return [data.lastRunDate ? _c('span', {
          staticClass: "mr-1"
        }, [_vm._v(_vm._s(_vm._f("date")(data.lastRunDate, 'ddd Do MMM YYYY HH:mm')))]) : _vm._e(), data.lastRunReportId ? _c('router-link', {
          staticClass: "btn btn-sm btn-text-primary",
          attrs: {
            "to": {
              name: 'reports-download',
              params: {
                id: data.lastRunReportId
              }
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-file-invoice mr-1"
        }), _vm._v("Download")]) : _vm._e()];
      }
    }, {
      key: "enabled",
      fn: function fn(_ref5) {
        var data = _ref5.data;
        return [data.enabled ? _c('i', {
          staticClass: "fa fa-lg fa-circle-check text-success"
        }) : _c('i', {
          staticClass: "fa fa-lg fa-circle-xmark text-danger"
        })];
      }
    }, {
      key: "userSub",
      fn: function fn(_ref6) {
        var data = _ref6.data;
        return [_vm._v(" " + _vm._s(_vm._f("user")(data.userSub, _vm.users)) + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref7) {
        var data = _ref7.data;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-text-primary mr-2",
          on: {
            "click": function click($event) {
              return _vm.onClickEdit(data);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil mr-2"
        }), _vm._v("Edit")]), _c('button', {
          staticClass: "btn btn-sm btn-text-dark mr-2",
          on: {
            "click": function click($event) {
              return _vm.onClickSendTest(data);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-paper-plane mr-2"
        }), _vm._v("Send Test")]), _c('button', {
          staticClass: "btn btn-sm btn-text-danger",
          on: {
            "click": function click($event) {
              _vm.modals.delete = data;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("Delete")])])];
      }
    }])
  }), _c('Modal', {
    attrs: {
      "open": !!_vm.modals.edit,
      "title": "Schedule Report",
      "text": "Send out this report to users on a regular or one-off basis. You can edit scheduled reports at any time by going to the Reports > Schedule tab once created.",
      "lg-size": "",
      "loading": (_vm$modals$edit = _vm.modals.edit) === null || _vm$modals$edit === void 0 ? void 0 : _vm$modals$edit.saving
    },
    on: {
      "close": function close($event) {
        _vm.modals.edit = false;
      },
      "submit": _vm.onSaveReport
    }
  }, [_vm.modals.edit ? _c('ScheduledReportForm', {
    model: {
      value: _vm.modals.edit.form,
      callback: function callback($$v) {
        _vm.$set(_vm.modals.edit, "form", $$v);
      },
      expression: "modals.edit.form"
    }
  }) : _vm._e()], 1), _c('Modal', {
    attrs: {
      "open": !!_vm.modals.delete,
      "title": "Delete Schedule",
      "text": "Are you sure you want to unschedule this report? You can re-schedule it again at any time."
    },
    on: {
      "close": function close($event) {
        _vm.modals.delete = false;
      },
      "submit": _vm.onDeleteSchedule
    }
  }), _c('Modal', {
    attrs: {
      "open": !!_vm.modals.sendTest,
      "title": "Send Test",
      "confirm-text": "Send",
      "loading": _vm.scheduledReportsLoading.send
    },
    on: {
      "close": function close($event) {
        _vm.modals.sendTest = false;
      },
      "submit": _vm.onSendTest
    }
  }, [_vm._v(" Send an example of this scheduled report to your own email address "), _c('strong', [_vm._v(_vm._s(_vm.$auth.user.email))]), _vm._v(". ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }